import React from "react";
import styled from "@emotion/styled";

import { ReactComponent as KontaktIcon } from "../../icons/kontakt.svg";
import { ReactComponent as ImmobilienIcon } from "../../icons/immobilien.svg";
import { ReactComponent as FinanzierungIcon } from "../../icons/finanzierung.svg";
import { ReactComponent as LeistungenIcon } from "../../icons/leistungen.svg";
import { ReactComponent as SucheIcon } from "../../icons/suche.svg";
import { ReactComponent as KundeIcon } from "../../icons/kunde.svg";

const Icon = styled.div`
  svg {
    width: 90px;
    height: 90px;
  }
  path {
    fill: ${props => (props.colored ? "#961452" : "white")};
  }
`;

const Kranicon = ({ icon, colored }) => (
  <Icon colored={colored}>
    {icon === "kontakt" && <KontaktIcon />}
    {icon === "immobilien" && <ImmobilienIcon />}
    {icon === "finanzierung" && <FinanzierungIcon />}
    {icon === "leistungen" && <LeistungenIcon />}
    {icon === "suche" && <SucheIcon />}
    {icon === "kunde" && <KundeIcon />}
  </Icon>
);

export default Kranicon;