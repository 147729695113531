import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.image.src});
`;

const Content = styled.div`
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  background: linear-gradient(
    to right,
    rgba(105, 31, 131, 0.7) 0%,
    rgba(189, 18, 30, 0.7) 100%
  );
`;

const Background = ({ fluid, children }) => (
  <Container image={fluid}>
    <Content>{children}</Content>
  </Container>
);

export default Background;
