import styled from "@emotion/styled";

export default styled.h1`
  max-width: 900px;
  margin-top: 7px;
  margin-bottom: 20px;
  font-size: 1.6em;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  @media (min-width: 800px) {
    font-size: 2em;
  }
`;
