import React from "react";
import { Flex, Box } from "@rebass/grid/emotion";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Background from "../Background";
import PageTitle from "../PageTitle";

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 640px;
  padding: 15px;
  padding-top: 240px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  color: white;
  @media (min-width: 800px) {
    padding-top: 200px;
  }
`;

export const Intro = styled.p`
  font-size: 1.2em;
  @media (min-width: 800px) {
    font-size: 1.3em;
  }
`;

const BenefitList = styled.ul`
  margin: 0;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: center;
  li {
    margin: 5px;
    display: block;
    padding: 10px 20px;
    background: linear-gradient(45deg, #79b473, #89c372);
    border-radius: 100px;
    white-space: nowrap;
  }
`;

const Hero = ({ fluid, icon, intro, title, cta, benefits, children }) => (
  <Background fluid={fluid} alt={title}>
    <Tile>
      {children ? (
        children
      ) : (
        <>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            css={css`
              max-width: 40em;
            `}
          >
            {icon}
            <PageTitle>{title}</PageTitle>
            <Intro>{intro}</Intro>
            {cta}
            {benefits && (
              <Box mx={[0, 0, -8]}>
                <BenefitList>
                  {benefits.map((benefit) => (
                    <li key={benefit}>✓ {benefit}</li>
                  ))}
                </BenefitList>
              </Box>
            )}
          </Flex>
        </>
      )}
    </Tile>
  </Background>
);

export default Hero;
